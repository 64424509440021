export const FOLOW_US_ON_OUR_CHANNELS = "Follow us on our channels";

export const TEL = "tel:";
export const MAIL_TO = "mailto:";

// JUMBOTRON
export const WELCOME_TITLE = "Welcome to EISWERKS";
export const LET_EISWERKS = "Let EISWERKS ";
export const REDEFINE_EXP = "redefine your automotive experience.";
export const CONTACT_US_TO_SCHEDULE = "Contact us to schedule an appointment.";
export const CALL_US = "Call Us";

// Cookies
export const WE_ARE_USING_COOKIES = `We use cookies to enhance your user experience. By using our
website, you agree to our use of cookies.`;
export const ACCEPT = "Accept";

// Footer

export const WITH_EISWERKS = "with EISWERKS";

export const COMPANY_SUMMARY = "Company summary";
export const EISWERKS_TITLE = "Eiswerks - ";

export const AT_EISWERKS = "At EISWERKS,";
export const WE_ARE_OFFER = `we offer a comprehensive suite of services meticulously designed
to meet the discerning needs of vehicle owners.`;
export const CONTACT_US = "Contact us";
export const LINKS = "Links";
export const FOLLOW_US = "Follow us";

export const EMAIL = "Email";
export const PHONE = "Phone";
export const MESSAGE = "Message";
export const LOCATION = "Location";
export const FACEBOOK = "Facebook";
export const YOU_TUBE = "You Tube";
export const TWITER_X = "X";
export const TIK_TOK = "TikTok";
export const NAME = "Name";

export const CITY = "San Diego, CA, 92121";
export const SUITE = "Suite 907,";
export const STREET = "11839 Sorrento Valley Road";
export const MAKE_IT_SIMPLE = `© 2023 MSHD - make it simple`;

export const GET_IN_TOUCH = "Get In Touch";

export const WHEN_YOU_CHOOSE_EISWERK = `When you choose EISWERKS, you become part of a legacy built on
meticulous craftsmanship, unrivaled expertise, and a shared love
for extraordinary automobiles. Discover the artistry of automotive
care like never before with our exceptional services.`;

export const WHEN_YOU_COOSE_META_DESC = `When you choose EISWERKS, you become part of a legacy built on
meticulous craftsmanship, unrivaled expertise, and a shared love
for extraordinary automobiles`;

// errors

export const ERROR_EMPTY_FIELDS =
  "Message not sent, please fill in all fields.";
export const ERROR_NOT_SENT = "Message was not sent, please try again.";

export const MESSAGE_SENT = "Message was sent succesfully.";
