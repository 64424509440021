import React, { useEffect } from 'react';
import './NotFoundPage.css';

const NotFoundPage = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<div
				class='message-box'
				style={{ width: '90%', minHeight: '200px', marginTop: '20%' }}>
				<h1>404</h1>
				<p>Page not found</p>
				<div class='buttons-con'>
					<div class='action-link-wrap'>
						<p>
							Please choose some of the links from navigation menu
							and try again.
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default NotFoundPage;
