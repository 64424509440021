import React from "react";
import PropTypes from "prop-types";
import {
  StyledClose,
  StyledCloseBtn,
  StyledModalContainer,
  StyledModalContent,
} from "./Modal.styled";

const Modal = ({ toggleModal, title, children }) => {
  return (
    <div>
      <StyledModalContainer onClick={() => toggleModal()}>
        <StyledModalContent onClick={(e) => e.stopPropagation()} small>
          <StyledClose>
            <StyledCloseBtn onClick={() => toggleModal(false)}>
              &times;
            </StyledCloseBtn>
          </StyledClose>
          {title && <h1>{title}</h1>}
          <div>{children}</div>
        </StyledModalContent>
      </StyledModalContainer>
    </div>
  );
};

Modal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
};
Modal.defaultProps = {
  title: "",
  children: "",
};

export default Modal;
