import React from 'react';
import { StyledLogoHolder, StyledWrapper } from './Loader.styled';

const Loader = () => {
	return (
		<StyledWrapper>
			<StyledLogoHolder></StyledLogoHolder>
		</StyledWrapper>
	);
};

export default Loader;
