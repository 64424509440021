import styled from 'styled-components';

export const StyledCardsSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	margin-top: 20px;
	${(props) => props.hasBorderBottom && 'border-bottom: 3px solid #165277;'}
	@media (max-width: 689px) {
		flex-direction: column;
	}
`;

export const StyledHomeContent = styled.div`
	margin: auto;
	position: relative;

	top: 50px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
`;

export const StyledHomeTitle = styled.div`
	font-size: 32px;
	color: white;
	font-size: 16px;
	margin-bottom: 15px;

	&:hover {
		transform: scale(1.02) perspective(0px);
		text-shadow: 4px 7px 5px rgba(255, 255, 255, 0.33);
	}

	@media (min-width: 320px) {
		font-size: 20px;
	}

	@media (min-width: 375px) {
		font-size: 24px;
	}

	@media (min-width: 576px) {
		font-size: 28px;
	}

	@media (min-width: 768px) {
		font-size: 32px;
	}

	@media (min-width: 1200px) {
		font-size: 24px;
	}
`;
