import React, { useEffect, useState } from 'react';
import {
	StyleDescription,
	StyledMinorTitle,
	StyledDataDivHolder,
	StyledSubTitle,
	StyledTextSummary,
	StyledTitle,
	StyledWrapperDiv,
	StyledWrapperContent,
	StyledHFourTitle,
	StyledServiceUl,
} from './DataPageWrapper.styled';
import { blogContent } from '../../dataForCards/blogContent';
import useSize from '../../hooks/useSize';
import { Helmet } from 'react-helmet-async';
import { EISWERKS_TITLE } from '../../constants/app.const';
import logo from '../../assets/logo.png';
import { useCallback } from 'react';
import homepage1 from '../../assets/background/homepage1.jpeg';
import homepage2 from '../../assets/background/homepage2.jpeg';
import service from '../../assets/background/service.jpeg';
import dryBlast from '../../assets/background/dry_ice.jpeg';
import aboutAs from '../../assets/background/about.jpeg';
import ceramic from '../../assets/services/ServicesCeramicCoating.jpg';
import exquisite from '../../assets/background/exquisite.jpeg';
import contact from '../../assets/background/contact.jpeg';
import auction from '../../assets/background/auction_prep.jpeg';
import paintCorrection from '../../assets/background/paint_correction.jpeg';
import LazyImageComponent from '../../components/LazyImageComponent/LazyImageComponent';
import { useRef } from 'react';

const DataPageWrapper = ({ children }) => {
	const [data, setData] = useState(null);
	const [image, setImage] = useState(null);
	const [isItIOS, setItIOS] = useState(null);
	const [show, setShow] = useState(null);
	const windowSize = useSize();
	const ref = useRef(null);
	const scrollSection = sessionStorage.getItem('scrollSection');

	const windowLocation = window.location;

	const getImageForServices = (item) => {
		let imageSrc;
		switch (item?.title) {
			case 'Ceramic Coating':
				imageSrc = item.src;
				break;
			case 'Dry Ice Blasting':
				imageSrc = item.src;
				break;
			case 'Paint Correction':
				imageSrc = item.src;
				break;
			case 'Interior Detailing':
				imageSrc = item.src;
				break;
			case 'Undercarriage Restoration':
				imageSrc = item.src;
				break;
			case 'Customized Care':
				imageSrc = item.src;
				break;
			case 'Auction Preparation':
				imageSrc = item.src;
				break;
			case 'Auto Detailing':
				imageSrc = item.src;
				break;
			default:
				return null;
		}
		return (
			<LazyImageComponent
				src={item?.src}
				alt={item?.alt}
				height={windowSize < 768 ? '250px' : '350px'}
			/>
		);
	};

	const backgroundSetter = useCallback(() => {
		const source =
			sessionStorage.getItem('isItIOS') &&
			JSON.parse(sessionStorage.getItem('isItIOS'));
		const page = windowLocation.hash;
		switch (page) {
			case '#/services':
				setImage(service);
				setItIOS(source);
				break;
			case '#/dry-ice-blasting':
				setImage(dryBlast);
				setItIOS(source);
				break;
			case '#/about-us':
				setImage(aboutAs);
				setItIOS(source);
				break;
			case '#/advantages':
				setImage(ceramic);
				setItIOS(source);
				break;
			case '#/automotive-care':
				setImage(exquisite);
				setItIOS(source);
				break;
			case '#/auction-preparation':
				setImage(auction);
				setItIOS(source);
				break;
			case '#/contact':
				setImage(contact);
				setItIOS(source);
				break;
			case '#/paint-correction':
				setImage(paintCorrection);
				setItIOS(source);
				break;
			default:
				if (windowSize < 769) {
					setImage(homepage1);
					setItIOS(source);
				} else {
					setImage(homepage2);
					setItIOS(source);
				}
		}
	}, [windowSize, windowLocation.hash]);

	const paddingSetter = useCallback(() => {
		const size = windowSize;
		let padding = 0;
		switch (true) {
			case size > 476 && size < 769:
				padding = 10;
				break;
			case size > 768 && size < 1025:
				padding = 15;
				break;
			case size > 1024:
				padding = 20;
				break;
			default:
				padding = 0;
		}
		return padding;
	}, [windowSize]);

	const dataToShow =
		data &&
		data?.textDescription.map((item) => {
			if (
				item?.description?.subDescription &&
				item?.description?.subDescription.length
			) {
				return (
					<StyledDataDivHolder key={item.id}>
						<StyledSubTitle
							image={image}>{`${item?.title}`}</StyledSubTitle>
						<StyleDescription image={image}>
							{item?.description?.summary}
						</StyleDescription>
						<StyledDataDivHolder minor>
							<ul>
								{item?.description?.subDescription.map(
									(item) => (
										<li key={item?.id}>
											<StyledMinorTitle
												image={
													image
												}>{`${item?.title}`}</StyledMinorTitle>
											<StyleDescription
												image={image}
												minor>
												{item?.description}
											</StyleDescription>
										</li>
									)
								)}
							</ul>
						</StyledDataDivHolder>
					</StyledDataDivHolder>
				);
			} else {
				return (
					<StyledDataDivHolder
						key={item.id}
						ref={item?.title === scrollSection ? ref : null}>
						<StyledSubTitle image={image}>
							{`${item?.title}`}
						</StyledSubTitle>
						<StyleDescription image={image}>
							{item?.description}
						</StyleDescription>
						{data?.title === 'Services' &&
							getImageForServices(item)}

						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: `${
									windowSize < 650 && 'column'
								}`,
								flexWrap: `${windowSize > 650 && 'wrap'}`,
								justifyContent: 'space-evenly',
							}}>
							{data?.title === 'Services' &&
								item?.servicesSubDesc &&
								item?.servicesSubDesc?.map((subItem) => {
									return (
										<div
											style={{
												marginTop: '2rem',
												width: `${
													windowSize > 650 && '45%'
												}`,
											}}
											key={subItem?.title}>
											<StyledMinorTitle
												left={windowSize > 650}>
												{`${subItem?.title}`}
											</StyledMinorTitle>
											<StyledHFourTitle
												left={windowSize > 650}>
												{subItem?.subTitle}
											</StyledHFourTitle>
											<StyledServiceUl
												disc
												query={windowSize}>
												{subItem?.packageList?.map(
													(liItem) => (
														<li key={liItem}>
															{liItem}
														</li>
													)
												)}
											</StyledServiceUl>
											<StyledServiceUl
												top='1rem'
												query={windowSize}>
												{subItem?.packagePrice?.map(
													(liItem) => (
														<li>{liItem}</li>
													)
												)}
											</StyledServiceUl>
										</div>
									);
								})}
						</div>
					</StyledDataDivHolder>
				);
			}
		});

	const controlNavbar = useCallback(() => {
		if (window.innerWidth < 650 && window.scrollY < 100) {
			setShow(false);
		} else {
			setShow(true);
		}
	}, []);

	useEffect(() => {
		controlNavbar();
		window.addEventListener('scroll', controlNavbar);
		return () => {
			window.removeEventListener('scroll', controlNavbar);
		};
	}, [controlNavbar]);

	// useEffect(() => {
	// 	// Scroll to the element when the component mounts
	// 	if (ref?.current) {
	// 		ref.current.scrollIntoView({ behavior: 'smooth' });
	// 	}
	// }, []);

	useEffect(() => {
		!scrollSection && window.scrollTo(0, 0);
		setData(
			blogContent?.find((card) =>
				window.location.href.includes(card?.navLink)
			)
		);
		backgroundSetter();
	}, [backgroundSetter, scrollSection]);

	useEffect(() => {
		if (ref.current) {
			ref.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [data, scrollSection]);

	return (
		<>
			<Helmet>
				<meta charset='UTF-8' />
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1.0'
				/>
				{data && (
					<>
						<meta
							name='description'
							content={`${EISWERKS_TITLE}${data.title}${data.subTitle}`}
						/>
						<meta
							property='og:title'
							content={`${EISWERKS_TITLE}${data.title}`}
						/>
						<meta
							property='og:description'
							content={`${EISWERKS_TITLE}${data.title}${data.subTitle}`}
						/>
						<meta property='og:image' content={logo} />
						<meta
							name='twitter:title'
							content={`${EISWERKS_TITLE}${data.title}`}
						/>
						<meta
							name='twitter:description'
							content={`${EISWERKS_TITLE}${data.title}${data.subTitle}`}
						/>
						<meta name='twitter:image' content={logo} />
					</>
				)}
			</Helmet>
			<StyledWrapperDiv isItIOS={isItIOS}>
				<div
					style={{
						position: 'fixed',
						top: '0',
						left: '0',
						width: '100%',
						height: '110%',
						zIndex: '-100',
					}}>
					<LazyImageComponent src={image} alt='asd' height='100%' />
				</div>
				<StyledWrapperContent image={image} paddingX={paddingSetter()}>
					{windowSize < 650 && (
						<StyledTitle>{data?.title}</StyledTitle>
					)}
					{windowSize < 650 ? (
						<StyledSubTitle image={image}>
							{data?.subTitle}
						</StyledSubTitle>
					) : (
						<StyledTitle image={image}>
							{data?.subTitle}
						</StyledTitle>
					)}
					<StyledTextSummary image={image} widthBold>
						{data?.textSummary}
					</StyledTextSummary>
					{dataToShow}
					{children}
				</StyledWrapperContent>
			</StyledWrapperDiv>
		</>
	);
};

export default DataPageWrapper;
