import styled from "styled-components";

export const StyledButton = styled.div`
  position: fixed;
  width: 100%;
  right: 40px;
  bottom: 40px;
  height: 35px;
  width: 35px;
  font-size: 1.5rem;
  z-index: 1;
  cursor: pointer;
  color: #fff;
  border: 1px solid #165277;
  background: #165277;
  border-radius: 50%;
  ${(props) => !props.visible && `display: none;`}
  &:hover {
    color: #165277;
    background: #fff;
  }
`;
