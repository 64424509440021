export const linkHome = "Home";
export const linkRates = "Rates";
export const linkBlog = "Blog";
export const linkContact = "Contact Us";

export const urlInstagram = "//www.instagram.com/eiswerks/";
export const urlTikTok = "//www.tiktok.com/@eiswerks";
export const urlYouTube = "//youtube.com/@eiswerks?feature=shared";
export const urlFacebook =
  "//www.facebook.com/profile.php?id=100090103236098&mibextid=b06tZ0";
export const urlTwitter = "//twitter.com/eiswerks";

export const phoneNumber = "(619) 695 - 9948";
export const emailAddress = "info@eiswerks.com";
export const mshdEmail = "info@mshd-environment.com";
