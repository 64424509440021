import styled from 'styled-components';

export const StyledAppContainer = styled.div`
	text-align: center;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 60px;
`;

export const StyledContent = styled.div`
	width: 100%;
	height: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: 689px) {
		width: 100%;
	}
`;
