import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledHeaderWrapper = styled.header`
	background-color: rgba(255, 255, 255, 1);
	color: #165277;
	/* background-color: rgb(19, 22, 26); */
	/* background-image: linear-gradient(
		to bottom,
		rgb(97, 158, 212),
		rgb(221, 221, 221)
	); */
	position: fixed;
	top: ${({ top }) => (!top ? '-100px' : '0')};
	width: 100%;
	z-index: 999;
	animation: top 2s easy-in;
`;
export const StyledHeaderContent = styled.div`
	display: flex;
	align-items: ${(props) => props.alignItems};
	justify-content: space-between;
	padding: ${({ isItIOS }) => (isItIOS ? '0 40px 0 10px' : '0 5%')};
	min-height: 75px;
`;

export const StyledLogoLink = styled(Link)`
	color: #165277;
	font-size: 23px;
	letter-spacing: -1.5px;
	font-weight: 400;
	height: 70px;
	width|: 100px;
`;

export const StyledNavigation = styled.nav`
	transition: all 0.3s ease-in-out;
	@media (max-width: 650px) {
		position: fixed;
		top: 75px;
		right: 0;
		background-color: rgb(255, 255, 255);
		width: 100%;
		height: 100%;
		padding: 10px 0 25px;
		transform: ${({ hamburgerState }) =>
			!hamburgerState ? 'translateX(101%)' : 'translate(0)'};
	}
`;

export const StyledUl = styled.ul`
	display: flex;
	/* column-gap: 20px; */
	@media (min-width: 650px) {
		width: 500px;
	}
	@media (max-width: 650px) {
		flex-direction: column;
		align-items: flex-start;
		padding-left: 45px;
		row-gap: 20px;
	}
`;

export const StyledHideUl = styled.ul`
	position: absolute;
	top: 20px;
	left: 0;
	width: 300px;
	height: 225px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	row-gap: 20px;
	padding: 5px;
	z-index: 5;
	background: white;
	border-left: 1px solid #165277;
	border-bottom: 1px solid #165277;
`;

export const StyledLi = styled.li`
	position: relative;
	${(props) =>
		props.listWidth ? 'width: 250px; text-align: left;' : 'width: 120px;'}}
	${(props) => props.active === 'true' && `border-bottom: 1px solid #165277;`};
`;

export const StyledNavLink = styled(Link)`
	color: #165277;
	font-size: 13px;
	transition: all 0.4s;
	border-left: 1px solid rgb(22, 82, 119);
	border-top: 1px solid rgb(22, 82, 119);
	border-radius: 50px 0 0 0;
	${(props) =>
		props.borderno === 'withoutBorder' &&
		`
	border-left: none;
	border-top: none;
	border-radius: 0;
	padding: 3px;
	`}
	display: inline-block;
	width: 100%;
	&:active {
		box-shadow: 0 5px 5px 5px #165277;
	}
	&:hover,
	&:focus {
		color: white;
		background: #165277;
	}
	@media (max-width: 650px) {
		font-size: 14px;
		border-bottom: 1px solid #165277;
		border-right: none;
		&:active {
			box-shadow: 0 5px 5px 5px #165277;
		}
	}
		&:active {
			box-shadow: 0 5px 5px 5px #165277;
		}
	}
`;

export const StyledHamburger = styled.div`
	display: none;
	cursor: pointer;
	@media (max-width: 650px) {
		display: block;
	}
`;

export const StyledBar = styled.div`
	height: 2px;
	width: 27px;
	background-color: #165277;
	margin: 5px 0;
	opacity: 0.8;
	transition: all 0.3s ease-in-out;
	${({ hamburgerState }) =>
		hamburgerState &&
		`
				&:nth-child(1) {
					transform: translateY(7px) rotate(45deg);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:nth-child(3) {
					transform: translateY(-7px) rotate(-45deg);
				}
			`}
`;

export const StyledPhoneMenuWrapper = styled.div`
	display: flex;
	column-gap: ${({ isItIOS }) => (isItIOS ? '20px' : '30px')};
	${({ isItIOS }) => !isItIOS && 'flex-grow: 1;'};
	-webkit-box-pack: justify;
	justify-content: flex-end;
	padding-right: 25px;
	${(props) =>
		props.desk === 'true' &&
		`
		a {
			
			&:nth-child(1) {
					position: absolute;
					top: 0;
					right: 18%;
				}
			&:nth-child(2) {
				position: absolute;
				top: 0;
				right: 12%;
				}
			&:nth-child(3) {
				position: absolute;
				top: 0;
				right: 5%;				}
			}
		    
	`}
`;

export const StyledImageLogo = styled.img`
	width: 110px;
	height: 70px;
`;

export const StyledFontAwsomeLinks = styled(FontAwesomeIcon)`
	color: #165277;
	background: white;
	borderradius: 5px;
	display: block;
	padding: 20%;
	width: ${(props) => props.width}px;
	height: ${(props) => props.width}px;
`;
