import React from 'react';
import {
	StyledCompanySummaryText,
	StyledContainerCol,
	StyledContainerCopyRight,
	StyledContainerRow,
	StyledFooterContainer,
	StyledFooterLinks,
	StyledFooterWrapper,
	StyledH2Title,
	StyledLi,
	StyledLinksText,
} from './Footer.styled';
import { Link } from 'react-router-dom';
import routes from '../../navigation/RouteConst';
import * as linkMessages from '../../constants/navigation.const';
import * as messages from '../../constants/app.const';
import SocialMediaSection from '../SocialMediaSection/SocialMediaSection';

const Footer = () => {
	return (
		<StyledFooterWrapper>
			<StyledFooterContainer>
				<StyledContainerRow>
					<StyledContainerCol left>
						<StyledH2Title>
							{messages.COMPANY_SUMMARY}
						</StyledH2Title>
						<StyledCompanySummaryText>
							{messages.AT_EISWERKS}
							<br />
							{messages.WE_ARE_OFFER}
						</StyledCompanySummaryText>
					</StyledContainerCol>
					<StyledContainerCol left>
						<StyledH2Title>{messages.LINKS}</StyledH2Title>
						<ul>
							{routes.map(
								(link) =>
									link.navLinkName !== 'Learn more' &&
									link.navLinkName && (
										<StyledLi key={link.navLinkName}>
											<StyledFooterLinks to={link.path}>
												{link.navLinkName}
											</StyledFooterLinks>
										</StyledLi>
									)
							)}
						</ul>
					</StyledContainerCol>
					<StyledContainerCol left>
						<StyledH2Title>{messages.CONTACT_US}</StyledH2Title>
						<ul>
							<StyledLi position>
								<StyledFooterLinks
									to={`${messages.TEL}${linkMessages.phoneNumber}`}>
									<StyledLinksText>
										<span>{messages.PHONE}</span>
										<span>{linkMessages.phoneNumber}</span>
									</StyledLinksText>
								</StyledFooterLinks>
							</StyledLi>
							<StyledLi>
								<StyledFooterLinks
									to='#'
									onClick={() =>
										(window.location.href = `${messages.MAIL_TO}${linkMessages.emailAddress}`)
									}>
									<StyledLinksText>
										<span>{messages.EMAIL}</span>
										<span>{linkMessages.emailAddress}</span>
									</StyledLinksText>
								</StyledFooterLinks>
							</StyledLi>
							<StyledLi>
								<StyledFooterLinks to='/contact'>
									<StyledLinksText>
										<span>{messages.LOCATION}</span>
										<span>
											{messages.STREET}
											<br /> {messages.SUITE}
											<br /> {messages.CITY}
										</span>
									</StyledLinksText>
								</StyledFooterLinks>
							</StyledLi>
						</ul>
					</StyledContainerCol>
					<StyledContainerCol left>
						<StyledH2Title>{messages.FOLLOW_US}</StyledH2Title>
						<div>
							<SocialMediaSection footerLinks />
						</div>
					</StyledContainerCol>
				</StyledContainerRow>
				<StyledContainerCopyRight>
					<Link
						to='#'
						onClick={() =>
							(window.location.href = `${messages.MAIL_TO}${linkMessages.mshdEmail}`)
						}>
						<span>{messages.MAKE_IT_SIMPLE}</span>
					</Link>
				</StyledContainerCopyRight>
			</StyledFooterContainer>
		</StyledFooterWrapper>
	);
};

export default Footer;
