import styled from 'styled-components';

export const StyledWrapperDiv = styled.div`
	width: 100%;
	min-height: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	${({ image, isItIOS }) =>
		image &&
		`
		background: url(${image}) no-repeat;
		background-attachment: ${isItIOS ? 'scroll !important' : 'fixed'};
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-position: center center;
		background-size: cover;
		background-color: #165277;
	`}
`;
export const StyledWrapperContent = styled.div`
	width: 100%;
	min-height: 100vh;
	${({ image }) =>
		image &&
		`
		background-color: rgba(0, 0, 0, 0.4);

	`}
	${({ paddingX }) => `
		padding: 40px ${String(paddingX)}%;
	`}
`;

export const StyledTitle = styled.h1`
	width: 80%;
	margin: 10px auto;
	font-size: 24px;

	${({ image }) =>
		image &&
		image === 'services' &&
		`
		color: #265277 !important;
	`}
`;
export const StyledSubTitle = styled.h2`
	width: 80%;
	margin: 0.5rem auto;
	font-size: 19px;
	${(props) => props.left && 'text-align: left;'}

	${({ image }) =>
		image &&
		image === 'services' &&
		`
		color: #265277 !important;
	`}
`;
export const StyledMinorTitle = styled.h3`
	width: 80%;
	margin: 0.5rem ${(props) => (props.left ? 'auto' : '1rem')} 0.5rem 1rem;
	font-size: 16px;
	${(props) => props.left && 'text-align: left;'}

	${({ image }) =>
		image &&
		image === 'services' &&
		`
		color: #265277 !important;
	`}
`;
export const StyledHFourTitle = styled.h4`
	width: 80%;
	margin: 0.5rem ${(props) => (props.left ? 'auto' : '1rem')} 0.5rem 1rem;
	font-size: 16px;
	${(props) => props.left && 'text-align: left;'}

	${({ image }) =>
		image &&
		image === 'services' &&
		`
		color: #265277 !important;
	`}
`;

export const StyledTextSummary = styled.p`
	margin: 1rem auto;
	font-size: 16px;
	text-align: left;
	line-height: 1.4;
	${(props) => props.widthBold && `max-width: 80%; font-weight: 700;`}

	${({ image }) =>
		image &&
		image === 'services' &&
		`
		color: #265277 !important;
	`}
`;
export const StyleDescription = styled.p`
	margin: 1rem ${(props) => (props.left ? 'auto' : '1rem')} 1rem 1rem;
	font-size: 16px;
	text-align: left;
	line-height: 1.4;
	${(props) =>
		props.minor &&
		`font-size: 14px; margin: .5rem ${(props) =>
			props.left ? 'auto' : '.5rem'} .5rem .5rem;`}

	${({ image }) =>
		image &&
		image === 'services' &&
		`
		color: #265277 !important;
	`}
`;

export const StyledDataDivHolder = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 1.5rem auto;
	width: 100%;
	min-height: 150px;
	padding: 1rem
		${(props) =>
			props.minor &&
			`
        width: 90%;
        border: none;
        margin-top: 0;
        `};
`;

export const StyledServiceUl = styled.ul`
	color: white;
	list-style: ${({ disc }) => disc && 'disc'};
	text-align: left;
	width: ${({ disc, query }) => `${query < 650 && '58'}`}%;
	margin: 1rem auto;
	${({ top }) => top && `margin-top: ${top};`}
	max-width: 90%;
	li {
		margin-top: 0.5rem;
	}
`;
