import Contact from '../pages/Contact/Contact';
import Home from '../pages/Home/Home';
import * as text from '../constants/navigation.const';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import AuctionPreparation from '../pages/AuctionPreparation/AuctionPreparation';
import ExquisiteAutomotiveCare from '../pages/Exquisite Automotive Care/ExquisiteAutomotiveCare';
import AdvantagesOfCeramicCoating from '../pages/AdvantagesOfCeramicCoating/AdvantagesOfCeramicCoating';
import WhatIsDryIceBlasting from '../pages/WhatIsDryIceBlasting/WhatIsDryIceBlasting';
import Services from '../pages/Services/Services';
import AboutUs from '../pages/AboutUs/AboutUs';
import PaintCorrection from '../pages/PainColor/PaintCorrection';

const routes = [
	{
		path: '/',
		component: <Home />,
		navLinkName: text.linkHome,
	},
	{
		path: '/services',
		component: <Services />,
		navLinkName: 'Services',
	},
	{
		// path: '/services',
		// component: <Services />,
		navLinkName: 'Learn more',
	},
	{
		path: '/dry-ice-blasting',
		component: <WhatIsDryIceBlasting />,
		navLinkName: 'What Is Dry Ice Blasting',
		subLink: true,
	},
	{
		path: '/paint-correction',
		component: <PaintCorrection />,
		navLinkName: 'Paint Correction',
		subLink: true,
	},
	{
		path: '/advantages',
		component: <AdvantagesOfCeramicCoating />,
		navLinkName: 'Advantages of Ceramic Coating',
		subLink: true,
	},
	{
		path: '/automotive-care',
		component: <ExquisiteAutomotiveCare />,
		navLinkName: 'Exquisite Automotive Care',
		subLink: true,
	},
	{
		path: '/auction-preparation',
		component: <AuctionPreparation />,
		navLinkName: 'Auction Preparation',
		subLink: true,
	},
	{
		path: '/about-us',
		component: <AboutUs />,
		navLinkName: 'About Us',
	},
	{
		path: '/contact',
		component: <Contact />,
		navLinkName: text.linkContact,
	},
	{
		path: '*',
		component: <NotFoundPage />,
	},
];

export default routes;
