import styled from "styled-components";

export const StyledModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
`;

export const StyledModalContent = styled.div`
  width: 95%;
  max-width: 500px;
  min-height: ${(props) => (props.small ? "250" : "500")}px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledClose = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledCloseBtn = styled.span`
  width: 30px;
  height: 40px;
  border: 1px solid #165277;
  border-radius: 6px;
  background-color: #fff;

  font-size: 1.5rem;
  cursor: pointer;
  &:hover {
    background-color: #165277;
    color: #fff;
  }
`;
