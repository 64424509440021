export const homeData = [
	{
		id: 1,
		value: 'Dry Ice Blasting',
		color: 'rgb(113,182,214)',
		scrollSection: 'Dry Ice Blasting',
	},
	{
		id: 2,
		value: 'Automotive Detailing',
		color: 'rgb(106,168,197)',
		scrollSection: 'Auto Detailing',
	},
	{
		id: 3,
		value: 'Paint Correction',
		color: 'rgb(78,165,206)',
		scrollSection: 'Paint Correction',
	},
	{
		id: 4,
		value: 'Ceramic Coating',
		color: 'rgb(70,150,187)',
		scrollSection: 'Ceramic Coating',
	},
	{
		id: 5,
		value: 'Underbody Restoration',
		color: 'rgb(39,154,210)',
		scrollSection: 'Undercarriage Restoration',
	},
	{
		id: 6,
		value: 'Auction Preparation',
		color: 'rgb(39,153,209)',
		scrollSection: 'Auction Preparation',
	},
	{
		id: 7,
		value: 'Staging/Photography',
		color: 'rgb(16,81,115)',
		scrollSection: 'Auction Preparation',
	},
];
