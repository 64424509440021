import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledContainerCol = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  ${(props) => !props.noJustifyCon && `justify-content: space-between;`}
  align-items: center;
`;

export const StyledH4Title = styled.h4`
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 4rem;
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid white;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #165277;
    height: 2px;
    box-sizing: border-box;
    width: 100%;
  }
`;

export const StyledMediaHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  min-height: 200px;
  justify-content: space-between;
  row-gap: 20px;
`;

export const StyledFooterSocialMediaLinks = styled(Link)`
  display: inline-block;
  height: 125px;
  width: 125px;
  background-color: rgba(247, 248, 250, 0.952);
  text-align: center;
  line-height: 125px;
  border-radius: 6px;
  padding: 1.3%;
  color: #165277;
  transition: all 0.5s ease-in-out;
  &:hover {
    color: rgba(255, 255, 255, 1);
    background-color: #165277;
  }
  ${(props) =>
    props.footerlinks === "true" &&
    `
	height: 40px;
	width: 40px;
	margin: 0 10px 10px 0;
	line-height: 30px;
	border-radius: 50%;
	@media (min-device-width: 767px) and (max-device-width: 920px) {
	  height: 30px;
	  width: 30px;
	  line-height: 30px;
	}
	`}
`;
