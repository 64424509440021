export const blogContent = [
	{
		id: 1,
		title: 'Services',
		subTitle: 'Unleash Brilliance: Our Exclusive Services',
		textSummary:
			'At EISWERKS, we offer a comprehensive suite of services meticulously designed to meet the discerning needs of vehicle owners. Each service is tailored to preserve the uniqueness of your automotive treasure, ensuring it continues to turn heads wherever it goes.',
		textDescription: [
			{
				id: 1,
				src: 'images/ceramic_coating.jpg',
				alt: `Ceramic Coating`,
				title: `Ceramic Coating`,
				description: `Experience the pinnacle of vehicle protection with our premium ceramic coating service. We use advanced nano-ceramic formulas to create a durable, hydrophobic shield that repels water, road contaminants, and UV rays. Your car will stay cleaner for longer, while the paint is safeguarded against fading and oxidation.`,
			},
			{
				id: 2,
				src: 'images/dry_ice.jpg',
				alt: `Dry Ice Blasting`,
				title: `Dry Ice Blasting`,
				description: `Rediscover the true brilliance of your vehicle with our cutting-edge dry ice blasting technique. This gentle yet thorough cleaning process effectively removes dirt, grime, and contaminants without causing any damage to the car's finish or delicate components. Preserve your vehicle's impeccable condition with dry ice blasting.`,
			},
			{
				id: 3,
				src: 'images/paint_correction.jpg',
				alt: `Paint Correction`,
				title: `Paint Correction`,
				description: `We specialize in precision paint correction, meticulously enhancing the shine and clarity of your vehicle's paintwork. Through an intricate process, we remove imperfections, swirl marks, and minor scratches, leaving your car's exterior with an astonishing mirror-like finish.`,
			},
			{
				id: 4,
				src: 'images/interior.jpg',
				alt: `Interior Detailing`,
				title: `Interior Detailing`,
				description: `The interior of your extraordinary vehicle deserves no less attention than the exterior. We offer leather restoration using Leatherique products. This time consuming process which requires perfect conditions, restores softness and prolongs the life of your leather surfaces. We recommend this service every 6 to 12 months.`,
			},
			{
				id: 5,
				src: 'images/underbody.jpg',
				alt: `Undercarriage Restoration`,
				title: `Undercarriage Restoration`,
				description: `Using dry ice blasting and various techniques, we can restore and protect the undercarriage of your vehicle. By removing years of ware and tare, and the original coating, we can start fresh with a like-new surface and protect it for years to come.`,
			},
			{
				id: 6,
				src: 'images/customized_care.jpg',
				alt: `Customized Care`,
				title: `Customized Care`,
				description: `We understand that each vehicle is as unique as its owner. Our approach is fully tailored to cater to your car's specific needs, ensuring a bespoke experience that reflects your vehicle's unparalleled essence.`,
			},
			{
				id: 7,
				src: 'images/auction_prep.jpg',
				alt: `Auction Preparation`,
				title: `Auction Preparation`,
				description: `We offer full auction preparation services and high quality photographs including your undercarriage in order to ensure you get top dollar for your vehicle.`,
			},
			{
				id: 8,
				src: 'images/auto_detailing.jpg',
				alt: 'Auto Detailing',
				title: `Auto Detailing`,
				servicesSubDesc: [
					{
						title: 'PACKAGE #1',
						subTitle: 'BASIC DETAIL',
						packageList: [
							'INTERIOR STEAM CLEAN',
							'INTERIOR VACUUM',
							'INTERIOR BLOWOUT',
							'VENTS SANITIZED',
							'CLAY BAR - MECHANICAL DECONTAMINATION',
							'WAX OR CERAMIC SPRAY',
							'WHEEL CLEANING',
							'TIRES CLEANED AND DRESSED',
							'GLASS CLEANING',
						],
						packagePrice: [
							'CAR: $179 +',
							'MIDSIZE SUV: $199 +',
							'XL: $229 +',
						],
					},
					{
						title: 'PACKAGE #2',
						subTitle: 'INTERIOR FOCUS',
						packageList: [
							'INTERIOR STEAM CLEAN',
							'INTERIOR VACUUM',
							'INTERIOR BLOWOUT',
							'VENTS SANITIZED',
							'CARPET SHAMPOO AGITATION & EXTRACTION',
							'SEAT SHAMPOO AGITATION & EXTRACTION',
							'SPOT CLEANING',
							'GLASS CLEANED INSIDE & OUTSIDE',
							'CLAY BAR',
							'WAX OR CERAMIC SPRAY',
							'WHEEL CLEANING',
							'TIRES CLEANED AND DRESSED',
						],
						packagePrice: [
							'CAR: $289 +',
							'MIDSIZE SUV: $309 +',
							'XL: $339 +',
						],
					},
					{
						title: 'PACKAGE #3',
						subTitle: 'EXTERIOR FOCUS',
						packageList: [
							'INTERIOR STEAM CLEAN',
							'INTERIOR VACUUM',
							'INTERIOR BLOWOUT',
							'VENTS SANITIZED',
							'CLAY BAR - MECHANICAL DECONTAMINATION',
							'IRON REMOVER - CHEMICAL DECONTAMINATION',
							'GLASS CLEANED INSIDE & OUTSIDE',
							'1 YEAR CERAMIC SEALANT',
							'WHEEL CLEANING',
							'TIRES CLEANED & DRESSED',
							'TRIM CLEANED & DRESSED',
						],
						packagePrice: [
							'CAR: $289 +',
							'MIDSIZE SUV: $309 +',
							'XL: $339 +',
						],
					},
					{
						title: 'PACKAGE #4',
						subTitle: 'FULL DETAIL',
						packageList: [
							'INTERIOR STEAM CLEAN',
							'INTERIOR VACUUM',
							'INTERIOR BLOWOUT',
							'VENTS SANITIZED',
							'CARPET SHAMPOO AGITATION & EXTRACTION',
							'SEAT SHAMPOO AGITATION & EXTRACTION',
							'CLAY BAR - MECHANICAL DECONTAMINATION',
							'IRON REMOVER - CHEMICAL DECONTAMINATION',
							'GLASS CLEANED INSIDE & OUTSIDE',
							'1 YEAR CERAMIC SEALANT',
							'WHEEL CLEANING',
							'TIRES CLEANED & DRESSED',
							'TRIM CLEANED & DRESSED',
						],
						packagePrice: [
							'CAR: $349 +',
							'MIDSIZE SUV: $379 +',
							'XL: $399 +',
						],
					},
					{
						title: 'ADDITIONAL SERVICES',
						packageList: [
							'MAINTENANCE WASHES',
							'FULL PAINT CORRECTIONS',
							'3-5 YEAR CERAMIC COATINGS',
							'GLASS CERAMIC COATINGS',
							'INTERIOR CERAMIC COATINGS',
							'WHEEL CERAMIC COATINGS',
							'TRIM PAINT & CERAMIC COATINGS',
							'CHEMICAL FREE DETAILING',
							'HEADLIGHT RESTORATIONS',
							'ENGINE BAY CLEANING',
							'STAIN REMOVAL',
							'PET HAIR REMOVAL',
							'AC VENT CLEANING',
							'DRY ICE BLASTING',
							'UNDERCARRIAGE COATING AND RESTORATION',
						],
					},
				],
			},
		],
		readMore: 'Read more...',
		navLink: '/services',
		rateBtn: 'Some prices',
		homePagePosition: true,
	},
	{
		id: 2,
		title: 'What is Dry Ice Blasting',
		subTitle: 'The Unrivaled Brilliance of Dry Ice Blasting',
		textSummary: `Dry ice blasting is a revolutionary cleaning process that has transformed automotive restoration, especially for high-end, vintage, luxury, and supercar owners.
		This advanced technique harnesses the power of solid CO2 (dry ice) pellets to cleanse and restore vehicles without causing any damage.`,
		textDescription: [
			{
				id: 1,
				title: `How It Works`,
				description: `During dry ice blasting, small pellets of dry ice are propelled at high speed onto the vehicle's surface using compressed air. Upon impact, the pellets sublimate, turning from solid to gas, lifting away dirt, grime, and contaminants in the process.`,
			},
			{
				id: 2,
				title: 'The Benefits of Dry Ice Blasting:',
				description: {
					subDescription: [
						{
							id: 1,
							title: `Gentle and Non-Abrasive`,
							description: `Dry ice blasting is non-abrasive and non-toxic, ensuring the delicate finishes of your high-end vehicle remain unharmed during the cleaning process.`,
						},
						{
							id: 2,
							title: `Versatile Cleaning`,
							description: `From exteriors to engine bays and intricate interiors, dry ice blasting is versatile enough to clean every surface of your extraordinary vehicle effectively.`,
						},
						{
							id: 3,
							title: `Waterless and Eco-Friendly`,
							description: `The dry ice blasting process is entirely waterless, reducing water consumption and eliminating the need for harmful chemicals. This eco-friendly approach aligns perfectly with the values of responsible luxury vehicle owners.`,
						},
						{
							id: 4,
							title: `Efficient and Minimal Downtime: `,
							description: `Dry ice blasting offers minimal downtime, allowing you to get back on the road quickly and showcase your vehicle's immaculate condition.`,
						},
						{
							id: 5,
							title: `Stubborn Contaminant Removal`,
							description: `Dry ice blasting can effectively remove stubborn contaminants like tar, adhesives, and oil residues, leaving your vehicle's surface spotless and restored.`,
						},
					],
				},
			},
			{
				id: 3,
				title: `Unleash Brilliance with Dry Ice Blasting`,
				description: `Discover the transformative power of dry ice blasting and elevate your extraordinary vehicle's brilliance to new heights. At EISWERKS, our dry ice blasting service is tailored exclusively for OCD owners who demand the utmost next level care and attention for their automotive treasures.`,
			},
		],
		readMore: 'Read more...',
		navLink: '/dry-ice-blasting',
		homePagePosition: true,
	},
	{
		id: 3,
		title: 'About Us',
		subTitle: 'Welcome to EISWERKS: Elevating Your Automotive Experience',
		textSummary: `At EISWERKS, we take great pride in being San Diego's premier destination for high-end automotive care. As passionate enthusiasts ourselves, we understand the value of your prized possessions – vintage cars, luxury cars, specialty vehicles, supercars, hypercars, planes, boats and everything in between. Our team of experienced professionals is dedicated to delivering top-tier services, including ceramic coating, dry ice blasting, and paint correction, tailored exclusively for clients with refined tastes and a penchant for excellence.`,
		textDescription: [
			{
				id: 1,
				title: `Our Commitment`,
				description: `At EIWERKS, we believe in providing an unparalleled level of craftsmanship and attention to detail. Each vehicle that enters our facility is treated as a work of art, and our mission is to restore and enhance its brilliance to exceed your expectations.`,
			},
			{
				id: 2,
				title: `Expertise and Dedication`,
				description: `Our team is composed of highly skilled technicians who possess extensive experience in the automotive industry. We have a profound understanding of the unique needs of our customers. Combining our expertise with a passion for perfection, we offer a range of specialized services that redefine automotive elegance.`,
			},
			{
				id: 3,
				title: `Exceptional Results`,
				description: `From enhancing the luster of your vehicle's paintwork to preserving its flawless appearance with ceramic coating, we strive to achieve exceptional results that elevate your driving experience. At EISWERKS, we are driven by the pursuit of automotive excellence.`,
			},
			{
				id: 4,
				title: `Join the EISWERKS Experience`,
				description: `When you choose EISWERKS, you become part of a legacy built on meticulous craftsmanship, unrivaled expertise, and a shared love for extraordinary automobiles. Discover the artistry of automotive care like never before with our exceptional services.`,
			},
		],
		readMore: 'Read more...',
		navLink: '/about-us',
		rateBtn: 'Some prices',
	},
	{
		id: 4,
		title: 'Advantages of Ceramic Coating',
		subTitle: 'Preserve Brilliance: The Advantages of Ceramic Coating',
		textSummary: `As a specialty vehicle owner, you understand the importance of preserving your vehicle's brilliance and maintaining its showroom-like appearance. Ceramic coating is the ultimate solution for protecting and enhancing your automotive gem in the following ways`,
		textDescription: [
			{
				id: 1,
				title: `Unmatched Protection`,
				description: `Ceramic coating forms a strong protective layer over your vehicle's paintwork, shielding it from harmful UV rays, oxidation, bird droppings, tree sap, and other environmental contaminants.`,
			},
			{
				id: 2,
				title: `Hydrophobic Properties`,
				description: `The hydrophobic nature of ceramic coating ensures that water, dirt, and road grime slide off the surface effortlessly, making cleaning a breeze and reducing the need for frequent washing.`,
			},
			{
				id: 3,
				title: `Enhanced Gloss and Depth`,
				description: `With ceramic coating, your car's paintwork will exude an unparalleled depth and gloss, accentuating its lines and curves like never before.`,
			},
			{
				id: 4,
				title: `Long-Lasting Results`,
				description: `Unlike traditional waxing, ceramic coating provides long-lasting protection, ensuring your vehicle stays pristine for years to come.`,
			},
			{
				id: 5,
				title: `Scratch Resistance`,
				description: `While ceramic coating doesn't make your vehicle scratch-proof, it does add a layer of hardness that improves scratch resistance, reducing the risk of minor scratches during everyday use.`,
			},
			{
				id: 6,
				title: `Resale Value Boost`,
				description: `By preserving your vehicle's immaculate condition, ceramic coating can significantly enhance its resale value when the time comes to part ways with your automotive treasure.`,
			},
			{
				id: 7,
				title: `Invest in Brilliance`,
				description: `Ceramic coating is an investment in the brilliance and longevity of your high-end, vintage, luxury, or supercar. With this advanced protection, you can relish in the sheer beauty of your vehicle without worrying about its exposure to the elements..`,
			},
		],
		readMore: 'Read more...',
		navLink: '/advantages',
		homePagePosition: true,
	},
	{
		id: 5,
		title: 'Exquisite Automotive Care',
		subTitle: 'Indulge in Exquisite Automotive Care',
		textSummary:
			'At EISWERKS, we understand that your specialty vehicle deserves no less than exquisite care. Beyond our core services of paint correction, ceramic coating and dry ice blasting, we offer a range of additional services to ensure your vehicle remains in immaculate condition',
		textDescription: [
			{
				id: 1,
				title: 'Wheel and Tire Care',
				description: `Protect your wheels with the same level of care as your vehicles exterior paint. Have your wheels polished to perfection and coated to ensure long term preservation. `,
			},
			{
				id: 2,
				title: 'Engine Bay Detailing',
				description: `The heart of your extraordinary vehicle deserves meticulous attention. Our engine bay detailing service ensures that every inch of your engine compartment gleams with sophistication.k`,
			},
			{
				id: 3,
				title: 'Headlight Restoration',
				description: `Enhance both safety and aesthetics with our headlight restoration service. We rejuvenate cloudy and faded headlights, improving visibility and revitalizing your vehicle's front-end appearance.`,
			},
			{
				id: 4,
				title: 'Interior Protection',
				description: `The interior of your car is an extension of your refined taste. Our interior protection services include leather care, fabric protection, and thorough cleaning to maintain the elegance within your vehicle's cabin.`,
			},
			{
				id: 5,
				title: 'Embrace the Extraordinary',
				description: `Indulge in the extraordinary experience of automotive care at EISWERKS. Whether you desire the brilliance of ceramic coating, the rejuvenating power of dry ice blasting, or the meticulous touch of our additional services, your vehicle is in the hands of dedicated professionals who share your passion for automotive excellence.`,
			},
			{
				id: 6,
				title: 'Schedule an Appointment',
				description: `Experience the precision difference today. Contact us to schedule an appointment and unlock the full brilliance of your high-end, vintage, luxury, or supercar. Let EISWERKS redefine your automotive experience.`,
			},
		],
		readMore: 'Read more...',
		navLink: '/automotive-care',
		homePagePosition: true,
	},
	{
		id: 6,
		title: 'Auction Preparation',
		subTitle: 'Prepare Your Automotive Gem for the Auction Spotlight',
		textSummary: `At EISWERKS, we understand that participating in prestigious auctions is a thrilling opportunity for collectors of high-end, vintage, luxury, specialty and supercars. If you're seeking to sell your prized automotive gem, our auction preparation services ensures that your vehicle commands the spotlight it deserves.`,
		textDescription: [
			{
				id: 1,
				title: `Unveil the Full Brilliance`,
				description: `Preparing your extraordinary vehicle for an auction is not just about detailing; it's an art that requires a keen eye for detail and an understanding of the market. Our team of experts is well-versed in the intricacies of auction preparation, ensuring that every aspect of your car is presented flawlessly.`,
			},
			{
				id: 2,
				title: 'A Comprehensive Approach',
				description: {
					summary: `Our auction preparation service encompasses a range of specialized treatments for you to choose from, including:`,
					subDescription: [
						{
							id: 1,
							title: `Concours-Level Detailing`,
							description: `Our meticulous detailing process enhances every curve and contour, highlighting the beauty of your vehicle's design.`,
						},
						{
							id: 2,
							title: `Paint Correction`,
							description: `Our expert technicians meticulously correct any imperfections, ensuring that your car's paintwork gleams with a mirror-like finish.`,
						},
						{
							id: 3,
							title: `Engine Bay Showcase`,
							description: `We ensure that your engine bay is immaculate, capturing the attention of discerning collectors and enthusiasts.`,
						},
						{
							id: 4,
							title: `Interior Perfection`,
							description: `The interior of your vehicle is carefully cleaned and treated, exuding luxury and elegance.`,
						},
						{
							id: 5,
							title: `Photography Assistance`,
							description: `Capturing captivating images of your vehicle is crucial for creating a compelling auction presentation. We provide photography assistance to showcase your car's magnificence.`,
						},
					],
				},
			},
			{
				id: 3,
				title: `Optimize Auction Success`,
				description: `Our auction preparation service is tailored to optimize the potential value of your high-end, vintage, luxury, or supercar at auction. By entrusting your vehicle to Precision Automotive Elegance, you can confidently step onto the auction stage, knowing that your automotive masterpiece is presented in its most pristine and exquisite form.`,
			},
			{
				id: 4,
				title: `Experience the EISWERKS Advantage`,
				description: `Embrace the advantage of our auction preparation service. Let your high-end, vintage, luxury, or supercar radiate on the auction floor, captivating the attention of collectors and enthusiasts alike. At EISWERKS, we ensure that your automotive gem shines bright, leaving a lasting impression on every spectator and bidder.`,
			},
			{
				id: 5,
				title: `Schedule Your Auction Preparation`,
				description: `Make your auction appearance one to remember. Contact us today to schedule your auction preparation appointment, and embark on a journey where brilliance meets perfection. Experience the Precision Automotive Elegance advantage and unlock the true potential of your extraordinary vehicle at auction.`,
			},
		],
		readMore: 'Read more...',
		navLink: '/auction-preparation',
		homePagePosition: true,
	},
	{
		id: 7,
		title: 'Paint Correction',
		subTitle: 'Exploring the World of Paint Correction',
		textSummary: `Discover the art of paint correction and how it can transform your vehicle's appearance. Learn about the process, benefits, and why it's essential for maintaining your car's pristine finish`,
		textDescription: [
			{
				id: 1,
				title: `Introduction:`,
				description: `Welcome to our guide on paint correction—an essential process for reviving and enhancing your vehicle's paintwork. In this article, we'll delve into what paint correction is, why it matters, and how it can breathe new life into your car's exterior.`,
			},
			{
				id: 2,
				title: `What is Paint Correction?`,
				description: `Paint correction is a meticulous process designed to restore and enhance the appearance of a vehicle's paintwork. Over time, cars can accumulate imperfections such as swirl marks, scratches, oxidation, and water spots. Paint correction involves the careful removal of these defects to reveal a smooth and flawless finish.`,
			},
			{
				id: 3,
				title: 'Key Benefits of Paint Correction:',
				description: {
					summary: ``,
					subDescription: [
						{
							id: 1,
							title: `Restores Original Shine: `,
							description: `By eliminating surface imperfections, paint correction brings back the original gloss and shine of your car's paint.`,
						},
						{
							id: 2,
							title: `Enhances Resale Value: `,
							description: `A well-maintained exterior significantly boosts the resale value of your vehicle.`,
						},
						{
							id: 3,
							title: `Protects Against Environmental Damage: `,
							description: `Corrected paint is more resistant to environmental elements, providing a protective barrier against UV rays, bird droppings, and other contaminants.`,
						},
					],
				},
			},
			{
				id: 4,
				title: 'The Paint Correction Process:',
				description: {
					summary: ``,
					subDescription: [
						{
							id: 1,
							title: `Assessment`,
							description: `Our skilled technicians thoroughly inspect your vehicle's paint, identifying imperfections and planning the optimal correction process.`,
						},
						{
							id: 2,
							title: `Decontamination:`,
							description: `Chemical and mechanical decontamination prepare the surface for the machine polishing process, by removing all contaminants which have accrued on the paint over time. `,
						},
						{
							id: 3,
							title: `Machine Polishing:`,
							description: `Utilizing advanced machine polishing techniques, we carefully remove scratches, swirls, and other defects to achieve a smooth and uniform surface.`,
						},
						{
							id: 4,
							title: `Finishing Touches:`,
							description: ` A high-quality sealant, wax or ceramic coating is applied to protect the corrected paint, enhancing its longevity and providing a lasting, brilliant shine.`,
						},
					],
				},
			},
			{
				id: 8,
				title: 'Why Choose Paint Correction?',
				description: {
					summary: ``,
					subDescription: [
						{
							id: 1,
							title: `Preserve Your Investment: `,
							description: `Protect the value of your vehicle by addressing paint imperfections promptly.`,
						},
						{
							id: 2,
							title: `Professional Expertise`,
							description: `Our experienced technicians use industry-leading techniques and equipment to deliver exceptional results.`,
						},
						{
							id: 3,
							title: `Customized Solutions:`,
							description: `Each paint correction service is tailored to the unique needs of your vehicle, ensuring optimal results.`,
						},
					],
				},
			},
			{
				id: 6,
				title: ``,
				description: `Investing in paint correction is more than a cosmetic choice—it's a commitment to preserving the beauty and value of your vehicle. Contact us to schedule a paint correction service and witness the transformation firsthand.`,
			},
		],
		readMore: 'Read more...',
		navLink: '/paint-correction',
		homePagePosition: true,
	},
];
