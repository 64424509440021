import { useCookies } from "react-cookie";
import {
  StyledButton,
  StyledButtonWrapper,
  StyledConsentWrapper,
  StyledPElement,
} from "./CookiesConsent.styled";
import * as messages from "../../constants/app.const";

const CookieConsent = () => {
  const [, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <StyledConsentWrapper>
      <StyledPElement>{messages.WE_ARE_USING_COOKIES}</StyledPElement>
      <StyledButtonWrapper>
        <StyledButton onClick={giveCookieConsent}>
          {messages.ACCEPT}
        </StyledButton>
      </StyledButtonWrapper>
    </StyledConsentWrapper>
  );
};

export default CookieConsent;
