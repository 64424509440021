import React, { useCallback, useEffect, useState } from 'react';
import routes from '../../navigation/RouteConst';
import {
	StyledBar,
	StyledFontAwsomeLinks,
	StyledHamburger,
	StyledHeaderContent,
	StyledHeaderWrapper,
	StyledHideUl,
	StyledImageLogo,
	StyledLi,
	StyledLogoLink,
	StyledNavigation,
	StyledNavLink,
	StyledPhoneMenuWrapper,
	StyledUl,
} from './Header.styled';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import {
	faEnvelope,
	faPeopleGroup,
	faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { emailAddress, phoneNumber } from '../../constants/navigation.const';
import Modal from '../Modal/Modal';
import SocialMediaSection from '../SocialMediaSection/SocialMediaSection';
import useSize from '../../hooks/useSize';
import * as messages from '../../constants/app.const';

const Header = () => {
	const [hamburgerState, setHamburgerState] = useState(false);
	const [subMenu, setSubMenu] = useState(false);
	const [show, setShow] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const windowSize = useSize();

	const isItIOS =
		sessionStorage.getItem('isItIOS') &&
		JSON.parse(sessionStorage.getItem('isItIOS'));

	const divBar = [{ id: 1 }, { id: 2 }, { id: 3 }];

	const hamburgerContentState = show && hamburgerState;

	const handleHamburgerToggle = useCallback((e) => {
		if (e.target.innerText.toLowerCase() === 'learn more') {
			setSubMenu((prevState) => !prevState);
		} else {
			setSubMenu(false);
		}
		setHamburgerState((prevState) => !prevState);

		sessionStorage.removeItem('scrollSection');
	}, []);

	const controlNavbar = useCallback(() => {
		if (window.innerWidth < 650 && window.scrollY > 100) {
			setShow(false);
		} else {
			setShow(true);
		}
	}, []);

	useEffect(() => {
		controlNavbar();
		window.addEventListener('scroll', controlNavbar);
		return () => {
			window.removeEventListener('scroll', controlNavbar);
		};
	}, [controlNavbar]);

	const filterRoutes = routes.filter((route) => {
		return windowSize < 650 ? route.navLinkName !== 'Learn more' : route;
	});

	const links = filterRoutes.map(
		(link) =>
			link.navLinkName &&
			((windowSize < 650 && link.subLink) || !link.subLink) && (
				<StyledLi key={link.navLinkName} listWidth={windowSize < 650}>
					<StyledNavLink
						to={link.path}
						borderno={windowSize < 650 ? 'withoutBorder' : ''}
						onClick={handleHamburgerToggle}>
						{link.navLinkName}
					</StyledNavLink>
					{subMenu && link.navLinkName === 'Learn more' && (
						<StyledHideUl>
							{routes.map(
								(subLink) =>
									subLink.subLink && (
										<StyledLi
											key={subLink.navLinkName}
											listWidth={windowSize > 650}>
											<StyledNavLink
												to={subLink.path}
												borderno='withoutBorder'
												onClick={handleHamburgerToggle}>
												{subLink.navLinkName}
											</StyledNavLink>
										</StyledLi>
									)
							)}
						</StyledHideUl>
					)}
				</StyledLi>
			)
	);

	const hamburgerContent = divBar.map((item) => (
		<StyledBar hamburgerState={hamburgerContentState} key={item.id} />
	));

	return (
		<>
			<StyledHeaderWrapper top={show}>
				<StyledHeaderContent
					isItIOS={isItIOS}
					alignItems={windowSize < 650 ? 'center' : 'flex-end'}>
					<StyledLogoLink to='/'>
						<StyledImageLogo
							width='110px'
							height='70px'
							src={logo}
							alt='Eiswerks'
							title='Eiswerks'
							loading='eager'
						/>
					</StyledLogoLink>
					<StyledPhoneMenuWrapper
						isItIOS={isItIOS}
						desk={windowSize > 650 ? 'true' : ''}>
						<Link to='#' onClick={() => setShowModal(true)}>
							<StyledFontAwsomeLinks
								icon={faPeopleGroup}
								size={`${windowSize > 809 ? '3x' : '2x'}`}
								width={windowSize > 900 ? '40' : '30'}
							/>
						</Link>
						<Link
							to='#'
							onClick={() =>
								(window.location.href = `${messages.MAIL_TO}${emailAddress}`)
							}>
							<StyledFontAwsomeLinks
								icon={faEnvelope}
								size={`${windowSize > 809 ? '3x' : '2x'}`}
								width={windowSize > 900 ? '40' : '30'}
							/>
						</Link>
						<Link to={`${messages.TEL}${phoneNumber}`}>
							<StyledFontAwsomeLinks
								icon={faPhone}
								size={`${windowSize > 809 ? '3x' : '2x'}`}
								width={windowSize > 900 ? '40' : '30'}
							/>
						</Link>
					</StyledPhoneMenuWrapper>
					{windowSize < 650 ? (
						<>
							<StyledNavigation
								hamburgerState={hamburgerContentState}>
								<StyledUl>{links}</StyledUl>
							</StyledNavigation>
							<StyledHamburger onClick={handleHamburgerToggle}>
								{hamburgerContent}
							</StyledHamburger>
						</>
					) : (
						<>
							<StyledNavigation
								hamburgerState={hamburgerContentState}>
								<StyledUl>{links}</StyledUl>
							</StyledNavigation>
							<StyledHamburger onClick={handleHamburgerToggle}>
								{hamburgerContent}
							</StyledHamburger>
						</>
					)}
				</StyledHeaderContent>
			</StyledHeaderWrapper>
			{showModal && (
				<Modal toggleModal={setShowModal}>
					<SocialMediaSection noJustifyCon />
				</Modal>
			)}
		</>
	);
};

export default Header;
