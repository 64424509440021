import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledContactWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;
export const StyledReachUsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	min-height: 400px;
	@media (max-width: 1005px) {
		width: 100%;
	}

	@media (max-width: 809px) {
		flex-direction: column;
		align-items: center;
	}
`;

export const StyledContactForm = styled.div`
	width: 90%;
	margin: 50px auto;
	backdrop-filter: blur(5px);

	form {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		width: 100%;
		font-size: 16px;
		margin: 30px auto;
		color: white;
		input {
			width: 100%;
			height: 35px;
			padding: 7px;
			outline: none;
			border-radius: 5px;
			border: 1px solid #165277;
			&:focus {
				border: 2px solid #165277;
			}
		}
		textarea {
			max-width: 100%;
			min-width: 100%;
			width: 100%;
			max-height: 100px;
			min-height: 100px;
			padding: 7px;
			outline: none;
			border-radius: 5px;
			border: 1px solid #165277;
			resize: none;
			&:focus {
				border: 2px solid #165277;
			}
		}
		label {
			margin-top: 1rem;
		}
	}
	@media (min-width: 809px) {
		&:nth-child(1) {
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			transition: all 0.3s ease-in-out;
			padding: 30px 30px 30px 30px;
		}
		&:nth-child(2) {
			padding-left: 20px;
		}
	}
	@media (max-width: 809px) {
		width: 90%;
		&:nth-child(1) {
			margin-bottom: 0;
		}
		&:nth-child(2) {
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			transition: all 0.3s ease-in-out;
			padding: 30px 30px 30px 30px;
			margin: 20px auto;
		}
		&:nth-child(3) {
			margin: 20px auto 50px auto;
		}
	}
`;

export const StyledInput = styled.input`
	margin-top: 2rem;
	cursor: pointer;
	background: #165277;
	color: white;
	border: none;
	${(props) =>
		props.inputDisabled === 'disabled' &&
		`
      background: #F2F2F2;
      color: #165277;
  `}
`;

export const StyledContactMedia = styled.div`
	width: 45%;
`;

export const StyledMap = styled.div`
	width: 90%;
	height: 300px;
	margin: 5px auto;
	position: relative;
`;

export const StyledFrame = styled.form`
	border-radius: 10px;
	border: 0.5px solid #165277;
	box-shadow: 0 5px 5px 2px #999;
	padding: 2px 5px;
`;

export const StyledLinkHolder = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 25px;
	column-gap: 15px;
	row-gap: 15px;
	@media (max-width: 809px) {
		margin-top: 40px;
	}
`;

export const StyledLinks = styled.div`
	width: 47%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
`;
export const StyledContactLinks = styled.div`
	width: 100%;
	margin: 50px auto;
	padding: 5px 20px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;
`;

export const StyledLinkWrap = styled(Link)`
	display: flex;
	flex-direction: column;
	width: 100px;
`;

export const StyledFontAwsome = styled(FontAwesomeIcon)`
	color: #165277;
	background: white;
	border-radius: 5px;
	display: block;
	padding: 20%;
	width: ${(props) => (props.width === 'true' ? '40' : '30')}px;
	height: ${(props) => (props.width === 'true' ? '40' : '30')}px;
`;

export const StyledP = styled.p`
	text-align: left;
	padding: 5px;
	padding-left: 0;
	font-size: 14px;
`;

export const StyledModalP = styled.p`
	padding: 75px 0;
	border: 1px solid #165277;
	color: #165277;
	border-radius: 6px;
	margin-top: 50px;
	margin-bottom: 50px;
`;

export const StyledH1 = styled.h1`
	font-size: 32px;
	margin-bottom: 10px;
`;
