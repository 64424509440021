import React, { useRef, useState } from 'react';

import { faMapLocation, faPhone } from '@fortawesome/free-solid-svg-icons';
import {
	faFacebook,
	faTiktok,
	faXTwitter,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
	StyledContactForm,
	StyledContactLinks,
	StyledContactWrapper,
	StyledFontAwsome,
	StyledLinkHolder,
	StyledLinkWrap,
	StyledLinks,
	StyledMap,
	StyledReachUsWrapper,
	StyledP,
	StyledInput,
	StyledModalP,
	StyledH1,
} from './Contact.styled';
import {
	phoneNumber,
	urlFacebook,
	urlTikTok,
	urlTwitter,
	urlYouTube,
} from '../../constants/navigation.const';
import axios from 'axios';
import useSize from '../../hooks/useSize';
import * as messages from '../../constants/app.const';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import Modal from '../../components/Modal/Modal';
import logo from '../../assets/logo.png';
import DataPageWrapper from '../DataPageWrapper/DataPageWrapper';

const Contact = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');
	const [modalMessage, setModalMessage] = useState('');
	const [inputDisabled, setInputDisabled] = useState('');
	const [showModal, setShowModal] = useState(false);

	const windowSize = useSize();

	const map = useRef(null);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleClick = () => {
		map.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const sendEmail = async (e) => {
		e.preventDefault();
		const data = {
			service_id: process.env.REACT_APP_SERVICE_ID,
			template_id: process.env.REACT_APP_TEMPLATE_ID,
			user_id: process.env.REACT_APP_USER_ID,
			template_params: {
				from_name: name,
				from_email: email,
				to_name: 'Eiswerks',
				message: message + ` - My Phone Number: ${phone}`,
			},
		};

		if (name.length && email.length && message.length && phone.length) {
			setInputDisabled('disabled');
			try {
				await axios.post(
					'https://api.emailjs.com/api/v1.0/email/send',
					data
				);

				setName('');
				setEmail('');
				setPhone('');
				setMessage('');
				setModalMessage(messages.MESSAGE_SENT);
				setShowModal(true);
			} catch (err) {
				console.log(err);
				setShowModal(true);
			}
		} else {
			setModalMessage(messages.ERROR_EMPTY_FIELDS);
			setShowModal(true);
			setInputDisabled('');
		}
	};

	useEffect(() => {
		if (!showModal) {
			setModalMessage('');
			setInputDisabled('');
		}
	}, [showModal]);

	const handleCapitalize = (val) => {
		let capitaFirstLetter = '';
		if (val && typeof val === 'string' && val.length > 0) {
			const stringValue = val;
			capitaFirstLetter =
				stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
			return capitaFirstLetter;
		}
		return capitaFirstLetter;
	};

	return (
		<>
			<Helmet>
				<title>{`${messages.EISWERKS_TITLE}${messages.CONTACT_US}`}</title>
				<meta
					name='description'
					content={messages.WHEN_YOU_COOSE_META_DESC}
				/>
				<meta charset='UTF-8' />
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1.0'
				/>
				<link rel='canonical' href={window.location.href} />
				<link rel='canonical' href={window.location.href} />
				<meta property='og:url' content='https://eiswerks.com' />
				<meta property='og:type' content='website' />
				<meta property='og:title' content='Welcome to Eiswerks' />
				<meta
					property='og:description'
					content='we offer a comprehensive suite of services
            meticulously designed to meet the discerning needs
            of vehicle owners.'
				/>
				<meta property='og:image' content={logo} />
				<meta name='twitter:card' content='summary_large_image' />
				<meta name='twitter:title' content='Welcome to Eiswerks' />
				<meta
					name='twitter:description'
					content='we offer a comprehensive suite of services
            meticulously designed to meet the discerning needs
            of vehicle owners.'
				/>
				<meta name='twitter:image' content={logo} />
			</Helmet>
			<DataPageWrapper>
				<StyledContactWrapper>
					<StyledReachUsWrapper>
						{windowSize < 809 && (
							<StyledContactForm>
								<StyledH1>
									{messages.GET_IN_TOUCH}
									<br />
									{messages.WITH_EISWERKS}
								</StyledH1>
							</StyledContactForm>
						)}
						<StyledContactForm>
							<form onSubmit={sendEmail}>
								<label>{messages.NAME}</label>
								<input
									type='text'
									name='user_name'
									value={name}
									onChange={(e) => {
										const name = e?.target?.value;
										const capitalizeFirstLetter =
											handleCapitalize(name);
										capitalizeFirstLetter
											? setName(capitalizeFirstLetter)
											: setName('');
									}}
									onFocus={() => {
										if (
											modalMessage ===
											messages.ERROR_NOT_SENT
										) {
											setName('');
											setInputDisabled('');
										}
									}}
								/>
								<label>{messages.EMAIL}</label>
								<input
									type='email'
									name='user_email'
									value={email}
									onChange={(e) => setEmail(e?.target?.value)}
									onFocus={() => {
										if (
											modalMessage ===
											messages.ERROR_NOT_SENT
										) {
											setEmail('');
											setInputDisabled('');
										}
									}}
								/>
								<label for='phone'>{messages.PHONE}</label>
								<input
									type='tel'
									name='user_phone'
									value={phone}
									onChange={(e) => setPhone(e?.target?.value)}
									onFocus={() => {
										if (
											modalMessage ===
											messages.ERROR_NOT_SENT
										) {
											setPhone('');
											setInputDisabled('');
										}
									}}
								/>
								<label>{messages.MESSAGE}</label>
								<textarea
									name='message'
									value={message}
									onChange={(e) => {
										const message = e?.target?.value;
										const capitalizeFirstLetter =
											handleCapitalize(message);
										capitalizeFirstLetter
											? setMessage(capitalizeFirstLetter)
											: setMessage('');
									}}
									onFocus={() => {
										if (
											modalMessage ===
											messages.ERROR_NOT_SENT
										) {
											setMessage('');
											setInputDisabled('');
										}
									}}
								/>
								<StyledInput
									type='submit'
									value='Send'
									disabled={inputDisabled}
									inputDisabled={inputDisabled}
								/>
							</form>
						</StyledContactForm>
						<StyledContactLinks>
							{windowSize > 809 && (
								<StyledH1>
									{messages.GET_IN_TOUCH}
									<br />
									{messages.WITH_EISWERKS}
								</StyledH1>
							)}
							<p style={{ textAlign: 'left' }}>
								{messages.WHEN_YOU_CHOOSE_EISWERK}
							</p>
							<StyledLinkHolder>
								<StyledLinks>
									<StyledLinkWrap onClick={handleClick}>
										<StyledFontAwsome
											icon={faMapLocation}
											size={`${
												windowSize > 809 ? '3x' : '2x'
											}`}
											width={`${
												windowSize > 900 ? '40' : '30'
											}`}
											height={`${
												windowSize > 900 ? '40' : '30'
											}`}
										/>
										<StyledP>
											{messages.LOCATION}
											<br />
										</StyledP>
									</StyledLinkWrap>
								</StyledLinks>
								<StyledLinks>
									<StyledLinkWrap to={`tel:${phoneNumber}`}>
										<StyledFontAwsome
											icon={faPhone}
											size={`${
												windowSize > 809 ? '3x' : '2x'
											}`}
											width={`${
												windowSize > 900 ? '40' : '30'
											}`}
											height={`${
												windowSize > 900 ? '40' : '30'
											}`}
										/>
										<StyledP>
											{messages.PHONE}
											<br />
										</StyledP>
									</StyledLinkWrap>
								</StyledLinks>
								<StyledLinks>
									<StyledLinkWrap to={urlFacebook}>
										<StyledFontAwsome
											icon={faFacebook}
											size={`${
												windowSize > 809 ? '3x' : '2x'
											}`}
											width={`${
												windowSize > 900 ? '40' : '30'
											}`}
											height={`${
												windowSize > 900 ? '40' : '30'
											}`}
										/>
										<StyledP>
											{messages.FACEBOOK}
											<br />
										</StyledP>
									</StyledLinkWrap>
								</StyledLinks>
								<StyledLinks>
									<StyledLinkWrap to={urlTwitter}>
										<StyledFontAwsome
											icon={faXTwitter}
											size={`${
												windowSize > 809 ? '3x' : '2x'
											}`}
											width={`${
												windowSize > 900 ? '40' : '30'
											}`}
											height={`${
												windowSize > 900 ? '40' : '30'
											}`}
										/>
										<StyledP>
											{messages.TWITER_X}
											<br />
										</StyledP>
									</StyledLinkWrap>
								</StyledLinks>
								<StyledLinks>
									<StyledLinkWrap to={urlYouTube}>
										<StyledFontAwsome
											icon={faYoutube}
											size={`${
												windowSize > 809 ? '3x' : '2x'
											}`}
											width={`${
												windowSize > 900 ? '40' : '30'
											}`}
											height={`${
												windowSize > 900 ? '40' : '30'
											}`}
										/>
										<StyledP>
											{messages.YOU_TUBE}
											<br />
										</StyledP>
									</StyledLinkWrap>
								</StyledLinks>
								<StyledLinks>
									<StyledLinkWrap to={urlTikTok}>
										<StyledFontAwsome
											icon={faTiktok}
											size={`${
												windowSize > 809 ? '3x' : '2x'
											}`}
											width={`${
												windowSize > 900 ? '40' : '30'
											}`}
											height={`${
												windowSize > 900 ? '40' : '30'
											}`}
										/>
										<StyledP>
											{messages.TIK_TOK}
											<br />
										</StyledP>
									</StyledLinkWrap>
								</StyledLinks>
							</StyledLinkHolder>
						</StyledContactLinks>
					</StyledReachUsWrapper>
					<StyledMap ref={map}>
						<iframe
							title='where_we_are'
							src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.2909274702793!2d-117.23971148815409!3d32.916910976712565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dc0644b9f2f84b%3A0x6a1f35319e49243c!2zMTE4MzkgU29ycmVudG8gVmFsbGV5IFJkLCBTYW4gRGllZ28sIENBIDkyMTIxLCDQodGY0LXQtNC40ZrQtdC90LUg0JTRgNC20LDQstC1!5e0!3m2!1ssr!2srs!4v1696796656086!5m2!1sen!2sus&language=en'
							width='100%'
							height='300'
							loading='lazy'></iframe>
					</StyledMap>
				</StyledContactWrapper>
				{showModal && (
					<Modal toggleModal={setShowModal} small>
						<StyledModalP>{modalMessage}</StyledModalP>
					</Modal>
				)}
			</DataPageWrapper>
		</>
	);
};

export default Contact;
