// LazyImage.js

import React from 'react';

const LazyImageComponents = ({ src, alt, height }) => {
	return (
		<div
			style={{
				width: '100%',
				height: height,
				position: 'relative',
				overflow: 'hidden',
			}}>
			<img
				src={src}
				alt={alt}
				style={{
					width: '100%',
					// height: '100%',
					// objectFit: 'cover',
					// position: 'absolute',
					// top: '0',
					// left: '0',
					height: '100%',
					objectFit: 'cover',
					position: 'absolute',
					top: '0px',
					left: '50%',
					transform: 'translateX(-50%)',
				}}
				loading='lazy'
			/>
		</div>
	);
};

export default LazyImageComponents;
