import styled from "styled-components";
import { keyframes } from "styled-components";
import logo from "../../assets/logo.png";

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 450px;
`;

export const StyledKeyFrame = keyframes`
    0% { height: 100px; width: 100px; }
    30% { height: 250px; width: 250px; opacity: 1 }
    40% { height: 405px; width: 405px; opacity: 0.3; }
    80% { height: 250px; width: 250px; opacity: 0.6; }
    100% { height: 100px; width: 100px; opacity: 0.6; }
`;

export const StyledLogoHolder = styled.div`
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100px;
  width: 100px;
  position: absolute;
  top: 35%;
  border-style: solid;
  border-width: 5px;
  border-color: black;
  border: 1px solid #165277;
  animation-name: ${StyledKeyFrame};
  animation-duration: 8s;
  animation-iteration-count: infinite;
`;
