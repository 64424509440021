import styled from 'styled-components';

export const StyledConsentWrapper = styled.div`
	position: sticky;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background: #165277;
	display: flex;
	align-items: center;
	color: white;
	font-size: 18px;
	padding: 0 5%;
	justify-content: space-between;
	@media (max-width: 789px) {
		height: 200px;
		flex-direction: column;
		justify-content: center;
	}
`;

export const StyledPElement = styled.p`
	width: 80%;
	text-align: left;
	color: white;
	@media (max-width: 789px) {
		flex-direction: column;
		margin: 5% auto;
		width: 100%;
	}
`;

export const StyledButtonWrapper = styled.div`
	width: 20%;
	@media (max-width: 789px) {
		margin-left: auto;
	}
`;
export const StyledButton = styled.button`
	display: block;
	margin-left: auto;
	height: 36px;
	width: 72px;
	background: #165277;
	color: white;
	border: 0.5px solid white;
	border-radius: 5px;
	&:hover {
		background: white;
		color: #165277;
		border-color: #165277;
	}
`;
