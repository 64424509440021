import { createGlobalStyle } from 'styled-components';
import bankGothik from '../assets/fonts/bankgothic-md-bt-cdnfonts/bank-gothic-medium-bt.ttf';

export const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: 'BankGothicMediumBT';
  src: local('BankGothicMediumBT'), url(${bankGothik}) format('truetype');
  font-display: swap;
}
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
      overflow-x: hidden;
      scroll-behavior: smooth;
      margin: 0;
      padding: 0;
      font-family: 'BankGothicMediumBT', sans-serif;
    body {
      background-color: rgb(255, 255, 255);
      margin: 0;
      padding: 0;
      h1, h2, h3, h4, h5, h6, p {
        color: white;
      }
      ul {
        list-style: none;
      }
      a {
        text-decoration: none;
        color: inherit;
      }
      button {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;
