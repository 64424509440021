import React, { useEffect } from 'react';
import { StyledHomeContent, StyledHomeTitle } from './Home.styled';
import DataPageWrapper from '../DataPageWrapper/DataPageWrapper';
import { homeData } from './home.const';
import { Link } from 'react-router-dom';

const Home = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<DataPageWrapper>
			<StyledHomeContent>
				{homeData.map((item) => {
					return (
						<StyledHomeTitle key={item.id}>
							<Link
								to='/services'
								onClick={() => {
									sessionStorage.setItem(
										'scrollSection',
										item.scrollSection
									);
								}}>
								{item.value}
							</Link>
						</StyledHomeTitle>
					);
				})}
			</StyledHomeContent>
		</DataPageWrapper>
	);
};

export default Home;
