import React from "react";
import PropTypes from "prop-types";
import {
  StyledContainerCol,
  StyledFooterSocialMediaLinks,
  StyledH4Title,
  StyledMediaHolder,
} from "./SocialMediaSection.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import {
  urlFacebook,
  urlInstagram,
  urlTikTok,
  urlTwitter,
  urlYouTube,
} from "../../constants/navigation.const";
import { FOLOW_US_ON_OUR_CHANNELS } from "../../constants/app.const";

const SocialMediaSection = ({ noJustifyCon, footerLinks }) => {
  const mediaHolder = [
    {
      url: urlFacebook,
      faIcon: faFacebook,
    },
    {
      url: urlInstagram,
      faIcon: faInstagram,
    },
    {
      url: urlTwitter,
      faIcon: faXTwitter,
    },
    {
      url: urlYouTube,
      faIcon: faYoutube,
    },
    {
      url: urlTikTok,
      faIcon: faTiktok,
    },
  ];

  const mapLinks = () => {
    return mediaHolder.map((icon) => (
      <StyledFooterSocialMediaLinks
        key={icon.url}
        to={icon.url}
        footerlinks={footerLinks.toString()}
      >
        <FontAwesomeIcon icon={icon.faIcon} size={footerLinks ? "1x" : "3x"} />
      </StyledFooterSocialMediaLinks>
    ));
  };

  const renderContent = () => {
    if (footerLinks) {
      return mapLinks();
    } else {
      return (
        <StyledContainerCol noJustifyCon={noJustifyCon}>
          <StyledH4Title>{FOLOW_US_ON_OUR_CHANNELS}</StyledH4Title>
          <StyledMediaHolder>{mapLinks()}</StyledMediaHolder>
        </StyledContainerCol>
      );
    }
  };

  return renderContent();
};

SocialMediaSection.propTypes = {
  noJustifyCon: PropTypes.bool,
  footerLinks: PropTypes.bool,
};
SocialMediaSection.defaultProps = {
  noJustifyCon: false,
  footerLinks: false,
};

export default SocialMediaSection;
