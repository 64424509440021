import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledFooterWrapper = styled.footer`
  margin-top: auto;
  width: 100%;
  background-color: #165277;
  padding: 70px 0 0 0;
`;

export const StyledFooterContainer = styled.div`
  max-width: 1170px;
  margin: auto;
`;

export const StyledContainerRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledContainerCol = styled.div`
  width: 25%;
  padding: 0 10px;
  ${(props) => props.left && `text-align: left;`}
  @media (max-width: 767px) {
    width: 50%;
    margin-bottom: 30px;
  }
  @media (max-width: 574px) {
    width: 100%;
  }
`;

export const StyledH2Title = styled.h2`
  font-size: 18px;
  color: white;
  text-transform: capitalize;
  margin-bottom: 2rem;
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #165277;
    height: 2px;
    box-sizing: border-box;
    width: 80%;
    margin-left: 10%;
  }
`;

export const StyledLi = styled.li`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  ${(position) =>
    position &&
    `position: relative;
	s`}
`;

export const StyledFooterLinks = styled(Link)`
  font-size: 16px;
  text-decoration: none;
  font-weight: 300;
  color: white;
  display: block;
  transition: all 0.3s ease;
  &:hover {
    color: #bbbbbb;
    padding-left: 4px;
  }
`;

export const StyledLinksText = styled.p`
  color: white;
  display: flex;
  justify-content: space-between;
  text-align: left;
  &:hover {
    color: #bbbbbb;
    padding-left: 4px;
  }
  span {
    font-size: 14px;
    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 70%;
    }
  }
`;

export const StyleFaIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 75%;
  transform: translateX(50%);
  margin-right: 5px;
`;
export const StyledContainerCopyRight = styled.div`
  text-align: center;
  color: white;
  border-top: 0.5px solid white;
  font-size: 12px;
  margin-top: 20px;
  span {
    display: block;
    padding-top: 5px;
  }
`;

export const StyledCompanySummaryText = styled.p`
  color: white;
  textalign: left;
  paddingright: 15px;
  lineheight: 1.4;
  font-weight: 300;
`;
