import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { StyledAppContainer, StyledContent } from './App.styled';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import routes from './navigation/RouteConst';
import CookieConsent from './components/CookiesConsent/CookiesConsent';
import Loader from './components/Loader/Loader';
import { useState } from 'react';
import { useEffect } from 'react';
import ScrollButton from './components/ScrollButton/ScrollButton';

const App = () => {
	const [cookies] = useCookies(['cookieConsent']);
	const [loading, setLoading] = useState(true);

	const routeComponents = routes.map((route, key) => (
		<Route exact path={route.path} element={route.component} key={key} />
	));

	useEffect(() => {
		if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
			sessionStorage.setItem('isItIOS', true);
		} else {
			sessionStorage.setItem('isItIOS', false);
		}
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, []);

	return (
		<StyledAppContainer>
			<Header />
			{loading && cookies.cookieConsent ? (
				<Loader />
			) : (
				<>
					<StyledContent>
						<Routes>{routeComponents}</Routes>
						{cookies.cookieConsent && <ScrollButton />}
					</StyledContent>
					<Footer />
				</>
			)}
			{!cookies.cookieConsent && <CookieConsent />}
		</StyledAppContainer>
	);
};

export default App;
